<template>
<flag
  :iso="isoCode"
  :squared="false"
  :class="styles"
/>
</template>

<script>
export default {
  props: ['countryCode', 'classes'],
  computed: {
    styles () {
      let showClasses = ''
      if (this.classes) {
        showClasses += this.classes
      }
      if (this.countryCode === 'srpska') {
        showClasses += ' flip-vertical' // starting space prevents collison w/ other classes
      }
      return showClasses
    },
    isoCode () {
      if (this.countryCode === 'srpska') {
        return 'ru'
      }
      return this.countryCode
    }
  }
}
</script>

<style scoped>
.flip-vertical {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
</style>
